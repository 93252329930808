import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = ({question, answer, gif_link}) => {
    return (
        <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6"><strong>{question}</strong></Typography>
      </AccordionSummary>

      {gif_link && <img src={gif_link} alt="GIF" 
        style={{ maxHeight: '200px', marginLeft: '10px' }} />}
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
    )
}

export default FAQ
 