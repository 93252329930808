import React from 'react';
import { Card, CardContent, CardActions, Typography, Button } from '@mui/material';


const BlogPostCard = ({ title, summary, onReadMore }) => {
    return (
      <Card sx={{ maxWidth: 400, margin: '1rem auto', padding: '1rem', boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {summary}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={onReadMore}>Read More</Button>
        </CardActions>
      </Card>
    );
  };

export default BlogPostCard;