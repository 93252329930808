import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { Home, User, FileText, Briefcase } from 'lucide-react';
import About from "./components/About"
import HomeBar from "./components/Home"
import Projects from "./components/Projects";
import Posts from "./components/Posts"
import "./App.css"
import Glossary from "./components/Glossary"
import SearchIcon from '@mui/icons-material/Search';

function NavLink({ to, children, icon: Icon }) {
  const location = useLocation();
  const isActive = location.pathname === to;
  
  return (
    <Link className={`link ${isActive ? 'active' : ''}`} to={to}>
      {Icon && <Icon size={16} />}
      <span>{children}</span>
    </Link>
  );
}

function App() {
  return (
    <Router>
      <nav className="navbar">
        <div className="nav-content">
          <NavLink to="" icon={Home}>Home</NavLink>
          <NavLink to="about" icon={User}>About</NavLink>
          <NavLink to="posts" icon={FileText}>Posts</NavLink>
          <NavLink to="glossary" icon={SearchIcon}>Glossary</NavLink>
          <NavLink to="projects" icon={Briefcase}>Projects</NavLink>
        </div>
      </nav>
      <main className="content">
        <Routes>
          <Route path="" element={<HomeBar />} />
          <Route path="posts" element={<Posts />} />
          <Route path="projects" element={<Projects />} />
          <Route path="about" element={<About />} />
          <Route path="glossary" element={<Glossary />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;