import { Typography } from "@mui/material"
import "./Posts.css"
import BlogPostCard from "./BlogPost"

function Posts(){



    return (
        <div>
            <Typography variant="h3">Here lies all the posts</Typography>
            <div>
                <BlogPostCard title="Hellowolrd" summary="this is a summary" />
            </div>
        </div>
    )
}

export default Posts