import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  TextField, 
  Grid2, 
  Card, 
  CardContent, 
  Chip, 
  Stack,
  Box
} from '@mui/material';

const BlogGlossaryWireframe = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState('All');

  const glossaryCategories = ['All', 'Technical', 'Industry', 'General'];
  const glossaryData = [
    { term: 'API', category: 'Technical', definition: 'Application Programming Interface' },
    { term: 'Blockchain', category: 'Technical', definition: 'Decentralized digital ledger technology' },
    { term: 'Synergy', category: 'Business', definition: 'Cooperative interaction of elements' }
  ];

  const filteredTerms = glossaryData
    .filter(item => 
      (activeFilter === 'All' || item.category === activeFilter) &&
      item.term.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4">
        Glossary
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, mb: 3, flexDirection: 'column'}}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search terms..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Stack direction="row" spacing={1}>
          {glossaryCategories.map(category => (
            <Chip
              key={category}
              label={category}
              color={activeFilter === category ? 'primary' : 'default'}
              onClick={() => setActiveFilter(category)}
            />
          ))}
        </Stack>
      </Box>

      <Grid2 container spacing={3}>
        {filteredTerms.map((item, index) => (
          <Grid2 item xs={12} sm={6} md={4} key={index}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" color="primary">
                  {item.term}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.definition}
                </Typography>
                <Chip 
                  label={item.category} 
                  size="small" 
                  sx={{ mt: 1 }} 
                />
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>

      {filteredTerms.length === 0 && (
        <Box textAlign="center" py={4}>
          <Typography color="text.secondary">
            No terms found matching your search.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default BlogGlossaryWireframe;